import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow
} from '@mui/material';
import UseAuthContext from 'context/AuthContext';
import UseLanguageContext from 'context/LanguageContext';
import DOMPurify from 'dompurify';
import moment from 'moment';
import { enqueueSnackbar, useSnackbar } from 'notistack';
import HReportBadge from 'pages/Revisions/HReportBadge';
import Report_1045 from 'pages/Revisions/Report_1045';
import RevisionError from 'pages/Revisions/RevisionError';
import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getAllRevisionFiles } from 'services/project-service';
import { runRevision } from 'services/revisions';
import { USER_ROLES } from 'utils/constants';
import { RevisionStatus } from 'utils/contracts';
import { formatFileName } from 'utils/Formatter';
import { getErrorHtmlContent } from 'utils/utils';
import ButtonCustom from './ButtonCustom';
import ButtonIcon from './ButtonIcon';
import CustomTableCell from './CustomTableCell';
import GradeBadge from './GradeBadge';
import Restrict from './Restrict';

export const RevisionNotes: React.FC<{ notes: string }> = ({ notes }) => {
  const formattedNotes = notes.replace(/\n/g, '<br/>');

  return (
    <div
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(formattedNotes, {
          ADD_ATTR: ['target'],
          ADD_TAGS: ['br']
        })
      }}
    />
  );
};

export const downloadFile = async (url: string, filename: string,auth:any,t:any) => {
  let name = filename;
  
  try {
   
    if(filename.includes('output')){
      const urlParts = url.split('/');
      const fileId = urlParts[urlParts.length - 3]   
      name = `output_${fileId}.json`;
    }
    if(filename.includes('input')){
      const urlParts = url.split('/');
      const fileId = urlParts[urlParts.length - 3]   
      name = `input_${fileId}.json`;
    }
    const response = await fetch(`${url}?authorization=${auth?.token}`);
    const blob = await response.blob();
    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = downloadUrl;
    link.download = name;
    document.body.appendChild(link);
    link.click();
    link.remove();
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    enqueueSnackbar(t('ERROR_DOWNLOADING_FILE'), { variant: 'error' });
  }
};

const RevisionRowData = ({
  props,
  projectId,
  index,
  refetchData,
  handleClickOpen,
  OrgId,
  hasTrial,
  totalPoints,
  projectType,
  handleClickCreditPopUpOpen,
  handleClickDeletePopUpOpen,
}: any) => {
  const {
    id,
    createdBy,
    updatedAt,
    status,
    transaction_id,
    requiredPoints,
    ticket,
    notes,
    weighed_grade,
    rating,
    files,
    h_report_pass,
    report_1045_pass,
  } = props;
  const { t } = useTranslation();
  const { language } = useContext(UseLanguageContext);
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const {
    role: authorization,
    organizations: organizationDetails,
    auth,
  } = useContext(UseAuthContext);
  const [showRevisionError, setShowRevisionError] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [openRow, setOpenRow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hover, setHover] = useState(false);
  const isViewer = authorization === USER_ROLES.VIEWER;
  const isRevisionSuccess = status === RevisionStatus.SUCCESS;
  const hasTransactionId = Boolean(transaction_id);
  const isViewerWithRevisionSuccessAndTransactionId =(isViewer && isRevisionSuccess && hasTransactionId) 

 

  const menuBasedOnStatus = () => {
    let items = 0;
    let editLink, downloadReportLinks, deleteLink, chartLink;
    let canDownloadRevision = !!transaction_id;
    if (
      [RevisionStatus.SUCCESS, RevisionStatus.CHECKED].includes(status) &&
      authorization !== USER_ROLES.VIEWER
    ) {
      editLink = (
        <>
          <MenuItem
            onClick={(e) =>
              status === RevisionStatus.SUCCESS
                ? ViewResult(e, id)
                : EditRevision(e, id)
            }
          >
            {status === RevisionStatus.SUCCESS
              ? t('REVISION_MENU_VIEW_RESULT')
              : t('REVISION_MENU_EDIT')}
          </MenuItem>
        </>
      );
      items++;
    } if ([RevisionStatus.SUCCESS].includes(status)) {
      editLink = (
        <>
          <MenuItem
            onClick={(e) => EditRevision(e, id)}
          >
            {t('REVISION_MENU_EDIT')}
          </MenuItem>
          <MenuItem onClick={(e) => ViewResult(e, id)}>
            {t('REVISION_MENU_VIEW_RESULT')}
          </MenuItem>

        </>
      );
      items++;
    }
    if (canDownloadRevision) {
      items++;
    }

    downloadReportLinks = (
      <>
        {canDownloadRevision && props.files.full && (
          <MenuItem>
            <a
              href={`${props.files.full.url}?authorization=${auth?.token}`}
              rel='noopener noreferrer'
              download
              className='dropdown-menu__link'
            >
              {t('REVISION_MENU_DOWNLOAD_DETAILED_REPORT')}
            </a>
          </MenuItem>
        )}
        {canDownloadRevision && props.files.rating && (
          <MenuItem>
            <a
              href={`${props.files.rating.url}?authorization=${auth?.token}`}
              rel='noopener noreferrer'
              download
              className='dropdown-menu__link'
            >
              {t('REVISION_MENU_DOWNLOAD_RATING_REPORT')}
            </a>
          </MenuItem>
        )}

        {canDownloadRevision && props.files.dxf && (
          <MenuItem>
            <a
              href={`${props.files.dxf.url}?authorization=${auth?.token}`}
              rel='noopener noreferrer'
              download
              className='dropdown-menu__link'
            >
              {t('REVISION_MENU_DOWNLOAD_3D_REPORT')}
            </a>
          </MenuItem>
        )}
        {canDownloadRevision && props.files.h_report && (
          <MenuItem>
            <a
              href={`${props.files.h_report.url}?authorization=${auth?.token}`}
              rel='noopener noreferrer'
              download
              className='dropdown-menu__link'
            >
              {t('REVISION_MENU_DOWNLOAD_H_REPORT')}{' '}
            </a>
          </MenuItem>
        )}

        {canDownloadRevision && props.files.report_1045 && (
          <MenuItem>
            <a
              href={`${props.files.report_1045.url}?authorization=${auth?.token}`}
              rel='noopener noreferrer'
              download
              className='dropdown-menu__link'
            >
              {t('REVISION_MENU_DOWNLOAD_1045_REPORT')}
            </a>
          </MenuItem>
        )}
        {authorization === USER_ROLES.ADMIN && props.files?.idf && (
          <MenuItem>
            <a
              href={`${props.files?.idf?.url}?authorization=${auth?.token}`}
              rel='noopener noreferrer'
              download
              className='dropdown-menu__link'
            >
              {t('REVISION_MENU_DOWNLOAD_IDF_FILE')}
            </a>
          </MenuItem>
        )}

        {authorization === USER_ROLES.ADMIN && props.files?.mod_idf && (
          <MenuItem>
            <a
              href={`${props.files?.mod_idf?.url}?authorization=${auth?.token}`}
              rel='noopener noreferrer'
              download
              className='dropdown-menu__link'
            >
              {t('REVISION_MENU_DOWNLOAD_RUN_FILE')}
            </a>
          </MenuItem>
        )}
        {authorization === USER_ROLES.ADMIN && props.files?.output_json && (
          <MenuItem onClick={() => downloadFile(
            props.files.output_json.url,
            props.files.output_json.name || 'output.json',
            auth,
            t
          )}>
            {t('REVISION_MENU_DOWNLOAD_OUTPUT_JSON_FILE')}
          </MenuItem>
        )}
        {authorization === USER_ROLES.ADMIN && props.files?.input_json && (
          <MenuItem onClick={() => downloadFile(
            props.files.input_json.url,
            props.files.input_json.name || 'input.json',
            auth,
            t
          )}>
            {t('REVISION_MENU_DOWNLOAD_INPUT_JSON_FILE')}
          </MenuItem>
        )}
   {/*      {authorization === USER_ROLES.ADMIN &&
          status !== RevisionStatus.FAILED &&
          props.files?.dsb && (
            <MenuItem>
              <a
                href={`${props.files.dsb.url}?authorization=${auth?.token}`}
                rel='noopener noreferrer'
                download
                className='dropdown-menu__link'
              >
                {t('REVISION_MENU_DOWNLOAD_DSB_MODEL_FILE')}
              </a>
            </MenuItem>
          )}
 */}
        {authorization === USER_ROLES.ADMIN &&
          status === RevisionStatus.SUCCESS &&
          props.files.h_report_csv && (
            <MenuItem
              
              onClick={() => downloadFile(
                props.files.h_report_csv.url,
                props.files.h_report_csv.name || 'h_report.csv',
                auth,
                t
              )}
             
                className='dropdown-menu__link'
              
                >
                {t('REVISION_MENU_DOWNLOAD_CSV_FILE')}
              
            </MenuItem>
          )}
      </>
    );

    if (
      ([
        RevisionStatus.VALIDATING,
        RevisionStatus.FAILED,
        RevisionStatus.CHECKED,
        RevisionStatus.SUCCESS,
      ].includes(status) ||
        (props.ticket && props.ticket.status === 'open')) &&
      !canDownloadRevision
    ) {
      items++;
      deleteLink = (
        <MenuItem onClick={(e) => handleClickDeletePopUpOpen(id)}>
          {t('REVISION_MENU_DELETE_REVISION')}
        </MenuItem>
      );
    }

    return [
      <>
        {editLink}
        {downloadReportLinks}
        <Restrict forbidden={['viewer']}>{deleteLink}</Restrict>
      </>,
      items > 0,
    ];
  };
  

  const run = async () => {
    try {
      const data = await runRevision(projectId, id, OrgId);
      if (data.status === 200) {
        refetchData();
      }
    } catch (error: any) {
      if (error) {
        enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
        console.log('onError', error);
      }
    }
  };

  const handleDownload = async () => {
    setIsLoading(true);
    try {
      await getAllRevisionFiles(OrgId, projectId, id);
    } catch (error) {
      enqueueSnackbar(getErrorHtmlContent(error), { variant: 'error' });
    } finally {
      setIsLoading(false);
    }
  };

  const showError = async () => {
    setShowRevisionError(true);
  };
  const ButtonsBasedOnStatus = () => {
    let action,
      text,
      disabled = '' as any,
      styleBtn = '',
      color = null,
      icon = null,
      menuDisabled = false;
    switch (status) {
      case RevisionStatus.CHECKED:
        action = { onClick: run };
        text = t('REVISION_CHECKED_STATUS_BUTTON_TEXT');
        styleBtn = 'revision-btn-run';
        if (authorization === USER_ROLES.VIEWER) {
          styleBtn += ' revision-btn-hide';
        }

        break;
      case RevisionStatus.FAILED:
        text = t('REVISION_ERROR_STATUS_BUTTON_TEXT');
        action = { onClick: showError };
        styleBtn = 'revision-btn-failed';
        break;
      case RevisionStatus.VALIDATING:
        text = t('REVISION_VALIDATING_STATUS_BUTTON_TEXT');
        disabled = 'revision-btn-validating';
        styleBtn = 'revision-btn-validating';
        icon = <CircularProgress thickness={2} sx={{ color: 'white', ml: language === 'he' ? '3px' : 0, mr: language === 'he' ? 0 : '3px' }} size={20} color="inherit" className='animation' />;
        break;
      case RevisionStatus.RESOLVING:
        if (!ticket) {
          text = t('REVISION_PENDING_STATUS_BUTTON_TEXT');
          disabled = 'revision-row-pending';
          styleBtn = 'revision-btn-pending spinning-grey';
          icon = <CircularProgress thickness={2} sx={{ color: 'grey', ml: language === 'he' ? '12px' : 0, mr: language === 'he' ? 0 : '12px' }} size={20} color="inherit" className='animation' />;
        } else {
          switch (ticket.status.toLowerCase()) {
            case 'open':
              action = {
                onClick: (e: any) => {
                  let url =
                    authorization === USER_ROLES.ADMIN
                      ? `/admin/business/${OrgId}/project/${projectId}/edit-ticket-dsb/${id}`
                      : `/project/${projectId}/edit-ticket-dsb/${id}`;
                  navigate(url);
                  e.stopPropagation();
                },
              };
              text = t('REVISION_RESOLVING_STATUS_CREATE_TICKET_BUTTON_TEXT');
              if (authorization === USER_ROLES.VIEWER) {
                styleBtn += ' revision-btn-hide';
              }
              //styleBtn = 'revision-btn-resolving-open';
              color = 'error';
              break;
            case 'ongoing':
              // Todo change to upload all files Page URL below code
              if (authorization === USER_ROLES.ADMIN) {
                action = {
                  onClick: (e: any) => {
                    let url =
                      authorization === USER_ROLES.ADMIN
                        ? `/admin/business/${OrgId}/project/${projectId}/revision/${id}/${ticket?.id}`
                        : `/project/${projectId}/revision/${id}`;
                    navigate(url);
                    e.stopPropagation();
                  },
                };
                text = t('REVISION_RESOLVING_STATUS_OPEN_TICKET_BUTTON_TEXT');
                //styleBtn = 'revision-btn-resolving-ongoing';
                color = 'orange';
              } else {
                text = t(
                  'REVISION_RESOLVING_STATUS_ONGOING_TICKET_BUTTON_TEXT'
                );
                disabled = 'revision-row-pending';
                styleBtn = 'revision-btn-pending spinning-grey';
                icon = <CircularProgress thickness={2} size={20} color="inherit" className='animation' />
              }
              break;
            default:
              break;
          }
        }
        break;
      case RevisionStatus.PROCESSING:
      case RevisionStatus.PENDING:
        text = t('REVISION_PENDING_STATUS_BUTTON_TEXT');
        disabled = 'revision-row-pending';
        styleBtn = 'revision-btn-pending spinning-grey';
        icon = <CircularProgress thickness={2} sx={{ color: 'grey' /* ml: language === 'he' ? '12px' : 0, mr: language === 'he' ? 0 : '12px' */ }} size={20} color="inherit" className='animation' />;
        break;
      case RevisionStatus.SUCCESS:
        if (transaction_id) {
          text = t('REVISION_SUCCESS_DOWNLOAD_BUTTON_TEXT');
          styleBtn = 'revision-btn-success';
          action = { onClick: handleDownload } as any;
          if (isLoading) {
            action = ""
            text = t('REVISION_PENDING_STATUS_BUTTON_TEXT');
            disabled = 'revision-row-pending';
            styleBtn = 'revision-btn-pending spinning-grey';
            icon = <CircularProgress thickness={2} sx={{ color: 'grey' }} size={20} color="inherit" className='animation' />
          };
        } else if (hasTrial || totalPoints >= requiredPoints) {
          action = { onClick: () => handleClickOpen({ id, requiredPoints }) };
          text = t('REVISION_SUCCESS_UNLOCK_BUTTON_TEXT')
          //styleBtn = 'revision-btn-success-locked';
          color = 'primary';
          if (['viewer'].includes(authorization ?? '')) {
            styleBtn += ' revision-btn-hide';
          }
        }
        else {
          action = { onClick: handleClickCreditPopUpOpen };
          text = t(`REVISION_SUCCESS_TOPUP_BUTTON_TEXT`);
          styleBtn = 'revision-btn-buy';
          if (['viewer'].includes(authorization ?? '')) {
            styleBtn += ' revision-btn-hide';
          }
        }
        break;
      default:
        break;
    }
    const isEnabled =
      action && action.onClick !== undefined && action.onClick !== null;
    const disabledAttribute = !isEnabled
      ? { disabled: true }
      : { disabled: false };

    return (
      <ButtonCustom
        size='small'
        fullWidth
        {...color && { color: color }}
        variant='contained'
        className={styleBtn}
        {...action}
        {...disabledAttribute}
        {...(icon && {
          [language === 'he' ? 'endIcon' : 'startIcon']: icon,
          /*   sx: {
              '& .MuiButton-endIcon': { marginRight: '8px' }
            } */
        })}
      >
        {text}
      </ButtonCustom>
    );
  };

  const EditRevision = (e: any, revisionID: any) => {
    if (authorization === USER_ROLES.ADMIN) {
      navigate(
        `/admin/business/${OrgId}/project/${projectId}/edit-revision/${revisionID}`
      );
    } else {
      navigate(
        `/business/${OrgId}/project/${projectId}/edit-revision/${revisionID}`
      );
    }
  };

  const ViewResult = (e: any, revisionID: any) => {
    if (authorization === USER_ROLES.ADMIN) {
      navigate(
        `/admin/business/${OrgId}/project/${projectId}/revision/${revisionID}/result`
      );
    } else {
      navigate(
        `/business/${OrgId}/project/${projectId}/revision/${revisionID}/result`
      );
    }
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setHover(false)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const isActiveButtonMore =
    [RevisionStatus.PENDING, RevisionStatus.PROCESSING, RevisionStatus.VALIDATING].includes(status) ||
      (props.ticket && props.ticket.status === 'ongoing') ? false : true ;
    
  return (
    <>
      <TableRow
        key={index}
        onMouseEnter={() => setHover(index)}
        onMouseLeave={() => setHover(null)}
      >
        <CustomTableCell clickable={false} status={status} collapseMode={notes} align='right'

          style={{ paddingLeft: "0px", border: "none", paddingRight: "0px" }} >
          {notes.length > 1 && (
            <IconButton
              aria-label='expand row'
              size='small'
              onClick={() => {
                setOpenRow(!openRow);
              }}
            >
              {openRow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </CustomTableCell>
        <CustomTableCell clickable={false} status={status} collapseMode onClick={(e) => status === RevisionStatus.SUCCESS ? ViewResult(e, id) : undefined}>
          <span>{index}</span>
        </CustomTableCell>
        <CustomTableCell clickable={false} status={status} collapseMode sx={{ minWidth: '170px', maxWidth: "170px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} onClick={(e) => status === RevisionStatus.SUCCESS ? ViewResult(e, id) : undefined} dir='ltr'
        >
          <span>{formatFileName(files?.['idf']?.name)}</span>
        </CustomTableCell>
        <CustomTableCell clickable={false} status={status} collapseMode sx={{ minWidth: '100px', maxWidth: "100px", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }} onClick={(e) => status === RevisionStatus.SUCCESS ? ViewResult(e, id) : undefined}>
          <span>{createdBy}</span>
        </CustomTableCell>
        <CustomTableCell clickable={false} status={status} collapseMode sx={{ minWidth: "130px" }} onClick={(e) => status === RevisionStatus.SUCCESS ? ViewResult(e, id) : undefined}>
          <span className='data'>
            {moment.utc(updatedAt).local().format('DD/MM/YY')}
          </span>
          <span className='time' style={{ marginLeft: '10px' }}>
            {moment.utc(updatedAt).local().format('HH:mm')}
          </span>
        </CustomTableCell>
        <CustomTableCell clickable={false} status={status} collapseMode onClick={(e) => status === RevisionStatus.SUCCESS ? ViewResult(e, id) : undefined}>
          <Report_1045 pass={report_1045_pass} />
        </CustomTableCell>
        {projectType == "Residential" && <CustomTableCell clickable={false} status={status} collapseMode onClick={(e) => status === RevisionStatus.SUCCESS ? ViewResult(e, id) : undefined}>
          <HReportBadge pass={h_report_pass} />
        </CustomTableCell>}
        <CustomTableCell dir="ltr" clickable={false} status={status} collapseMode onClick={(e) => status === RevisionStatus.SUCCESS ? ViewResult(e, id) : undefined}>
          {weighed_grade && parseFloat(weighed_grade).toFixed(2)}
        </CustomTableCell>
        <CustomTableCell clickable={false} status={status} collapseMode onClick={(e) => status === RevisionStatus.SUCCESS ? ViewResult(e, id) : undefined}>
          <GradeBadge rating={rating} />
        </CustomTableCell>

        <CustomTableCell clickable={false} collapseMode status={status}>
          {ButtonsBasedOnStatus()} <br></br>
        </CustomTableCell>
        <CustomTableCell clickable={false} status={status} collapseMode style={{ paddingLeft: "0px", border: "none", paddingRight: "0px" }} >
        {!isViewer ? isActiveButtonMore && (
            <Box >
              <ButtonIcon
                isActive={open}
                onClick={handleClick}
              >
                <MoreHorizIcon />
              </ButtonIcon>
              <Menu
                sx={{ fontSize: '14px' }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
              >
                <div>{menuBasedOnStatus()}</div>
              </Menu>
            </Box>
          ) : 
          isViewerWithRevisionSuccessAndTransactionId && (
          <Box >
          <ButtonIcon
            isActive={open}
            onClick={handleClick}
          >
            <MoreHorizIcon />
          </ButtonIcon>
          <Menu
            sx={{ fontSize: '14px' }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
          >
            <div>{menuBasedOnStatus()}</div>
          </Menu>
        </Box>
          )
          }
        </CustomTableCell>
      </TableRow>
      <TableRow sx={{ boxShadow: 'none !important' }}>
        <TableCell sx={{ paddingBottom: 0, paddingTop: 0, px: "59px" }} colSpan={11}>
          <Collapse in={openRow} timeout="auto" unmountOnExit>
            <RevisionNotes notes={notes} /> 
          </Collapse>
        </TableCell>
      </TableRow>
      {showRevisionError && (
        <RevisionError
          projectID={projectId}
          revisionID={id}
          businessID={OrgId}
          open={showRevisionError}
          setShowRevisionError={setShowRevisionError}
        />
      )}
    </>
  );
};

export default RevisionRowData;