import { Box, useTheme } from '@mui/material';
import Parcoords from 'parcoord-es';
import 'parcoord-es/dist/parcoords.css';
import { useEffect, useRef, useState } from 'react';

let chartView = null
interface Dimension {
  type: string;
  title: string;
  order: number;
}


const Chart = props => {
  const { data, colorSet, mouseOverRow, updateBrushedData, brushedData, dimensions, sortChartAxis, isDimensionsHidden, reloadChart } = props

  const chartRef = useRef(null);
  const [chartData, setChartData] = useState([...data])
  const theme = useTheme();
  const handleReset = () => {
    reloadChart(); 
  };

  useEffect(() => {
    setChartData([...data])
    handleReset()
  }, [data,isDimensionsHidden])


  useEffect(() => {

    if (chartRef !== null && chartData !== null) {
      chartRef.current.innerHTML = ''
      let brushed = {}
      brushedData.map(brush => {
        if (brush.selection.scaled.length !== 0) {
          if (brush.selection.scaled.length === 1)
            brush.selection.scaled.push(brush.selection.scaled[0])
          brushed[brush.axis] = brush.selection.scaled.sort((a, b) => a - b)
        }
        return true
      })
      const isRTL = theme.direction === 'rtl';
      const orderedDimensions = isRTL
        ? Object.keys(dimensions).reverse().reduce((obj, key) => {
          obj[key] = dimensions[key];
          return obj;
        }, {})
        : dimensions;

      chartView = Parcoords()(chartRef.current)

        .data(chartData)
        .color((d, i) => {
          try {
            return colorSet[d.rating].color
          } catch (error) {
            return 'grey'
          }
        })
        .alpha(0.4)
        .mode("queue")
        .margin({
          top: 100,
          left: 5,
          right: 5,
          bottom: 16
        })
        .dimensions(orderedDimensions)
        .hideAxis(["id", ...isDimensionsHidden])
        .render()
        .reorderable()
        .createAxes()
        .brushMode("1D-axes")
        .brushExtents(brushed)
        .on("brush", function (d, e) {
          updateBrushedData(d, e)
        })

          let texts = document.getElementsByClassName('label')
          for (let i = 0; i < texts.length; i++) {
            const element = texts[i] as SVGTextElement;
            element.style.pointerEvents = 'none';
            let fullText = element.getAttribute('label') || element.innerHTML;
            
            // Rotation du texte à 45 degrés
            element.setAttribute('dy', '-0.5em');
            element.setAttribute('dx', '0.5em');
            
            // Si le texte est trop long, le diviser en plusieurs lignes
            if (fullText.length > 20) {
              // Texte très long: diviser en trois parties
              element.textContent = '';
              
              // Calculer la longueur approximative de chaque segment
              const segmentLength = Math.ceil(fullText.length / 5);
              
              // Créer les trois segments
              const part1 = fullText.substring(0, segmentLength);
              const part2 = fullText.substring(segmentLength, 2 * segmentLength);
              const part3 = fullText.substring(2 * segmentLength, 3 * segmentLength);
              const part4 = fullText.substring(3 * segmentLength, 4 * segmentLength);
              const part5 = fullText.substring(4 * segmentLength, 5 * segmentLength);
              
              // Créer le premier tspan
              const tspan1 = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
              tspan1.textContent = part1;
              tspan1.setAttribute('x', '0');
              tspan1.setAttribute('dy', '-5.3em');
              element.appendChild(tspan1);
              
              // Créer le deuxième tspan
              const tspan2 = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
              tspan2.textContent = part2;
              tspan2.setAttribute('x', '0');
              tspan2.setAttribute('dy', '1.2em');
              element.appendChild(tspan2);
              
              // Créer le troisième tspan
              const tspan3 = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
              tspan3.textContent = part3;
              tspan3.setAttribute('x', '0');
              tspan3.setAttribute('dy', '1.2em');
              element.appendChild(tspan3);

              const tspan4 = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
              tspan4.textContent = part4;
              tspan4.setAttribute('x', '0');
              tspan4.setAttribute('dy', '1.2em');
              element.appendChild(tspan4);

              const tspan5 = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
              tspan5.textContent = part5;
              tspan5.setAttribute('x', '0');
              tspan5.setAttribute('dy', '1.2em');
              element.appendChild(tspan5);
            } 
            else if (fullText.length > 10) {
              // Texte moyennement long: diviser en deux parties
              element.textContent = '';
              
              const midpoint = Math.ceil(fullText.length / 2);
              const part1 = fullText.substring(0, midpoint);
              const part2 = fullText.substring(midpoint);
              
              const tspan1 = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
              tspan1.textContent = part1;
              tspan1.setAttribute('x', '0');
              tspan1.setAttribute('dy', '0');
              element.appendChild(tspan1);
              
              const tspan2 = document.createElementNS("http://www.w3.org/2000/svg", "tspan");
              tspan2.textContent = part2;
              tspan2.setAttribute('x', '0');
              tspan2.setAttribute('dy', '1.2em');
              element.appendChild(tspan2);
            }
          }
        
    }
  }, [chartRef, chartData, colorSet, brushedData, dimensions, theme.direction])


  useEffect(() => {
    if (mouseOverRow !== null) {
      const item_id = mouseOverRow.id
      const d = chartData;
      const elementPos = d.map(function (x) { return x.id; }).indexOf(item_id);
      if (elementPos > -1) chartView.highlight([d[elementPos]]);
    } else {
      colorSet !== null && chartView.unhighlight();
    }
  }, [mouseOverRow])

  return (
    <Box dir='ltr'>
      <div ref={chartRef} id={'chart-id'} style={{ height: 500 }} className={'parcoords'} />
    </Box>
  );
}

export default Chart;